<template>
	
	<div class="cont" id="p09" style="width: 100%; height: 100%; position: fixed; left: 0; top: 0; background: none; z-index: 9999;">
		<div class="popup-list" style="width: 100%; height: 100%; position: fixed; left: 0; top: 0;">
			<div class="popup type02" style="width: 550px; margin: 10% auto; transform: none; overflow: hidden; position: relative !important; z-index: 9999;">
				<div class="p-header">
					<div class="title">{{ program.name }}</div>
				</div>
				<div class="p-body">
					<div class="c01">
						<div class="pc-body">
							<div class="item_list">
								<div class="item">
									<div class="title"  style="font-size: 1.2em !important;">대표자 휴대폰 번호</div>
									<div class="desc"  style="font-size: 1.2em !important;">{{ item.basic.ceoMobilPhoneNumber }}</div>
								</div>
								<div class="item"
									v-if="item.manger.managerMobilPhoneNumber"
								>
									<div class="title" style="font-size: 1.2em !important;">담당자 휴대폰 번호</div>
									<div class="desc" style="font-size: 1.2em !important;">{{ item.manger.managerMobilPhoneNumber }}</div>
								</div>
							</div>
							
							<div style="margin-top: 30px; font-size: 1.2em; font-weight: bold;">등록된 연락처로 임시 비밀번호를 발급하여 전송합니다</div>
						</div>
					</div>
				</div>
				<div class="p-button">
					<v-row>
						<v-col>
							<v-btn
								class="success"
								style="background: #00bfa5;"
								block tile
								@click="confirm"
							>확인</v-btn>
						</v-col>
						<v-col>
							<v-btn
								class="success"
								style="background: #bbb;"
								block tile
								@click="close"
							>취소</v-btn>
						</v-col>
					</v-row>
				</div>
			</div>
		</div>
	</div>

</template>

<script>

	import { Axios } from '@/resources/axios/axios'
	
	export default{
		name: 'AgentPopupItem'
		,props: ['item']
		,data: function(){
			return {
				program: {
					name: '임시 비밀번호 발급'
				}
			}
		}
		,methods: {
			confirm: async function(){
				try{
					const result = await Axios({
						method: 'post'
						,url: '/sales/' + this.item.basic.userSeq + '/password'
						,data: {
							userSeq: this.item.basic.userSeq
						}
						,authorize: true
					})
					
					if(result.success){
						this.$emit('setNotify', { type: 'success', message: '임시 비밀번호가 발급되었습니다'})
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message})
					}
				}catch(E){
					this.$emit('setNotify', { type: 'error', message: E.status})
				}finally{
					this.$emit('setOverlay', false)
				}	
			}
			,close: function(){
					this.$emit('setOverlay', false)
			}
		}
	}
</script>